import { lazy, Suspense } from "react"
import { Switch, Route } from "react-router-dom"
import routes from "./config"
import { Styles, StylesV1, StylesV2, StylesV2Mobile } from "../styles/styles"

const Router = () => {

  const mobile = require('is-mobile')
  let v2 = (window.location.href.indexOf("v1")>-1)?false:true
  // let v2 = window.location.href.indexOf("v2")>-1
  let version = v2?mobile()?'v2/Mobile/':'v2/':''

  // FIXME: ajustar rotas
  if(window.location.href.indexOf("link")>-1)
    version = ''

  if(window.location.href.indexOf("napratica")>-1)
    version = 'v2/'

  if(window.location.href.indexOf("access")>-1)
    version = 'v2/'

  //force v2 until fix bug #102
  version = 'v2/'
  if(mobile())
    version = 'v2/Mobile/'

  return (
    <Suspense fallback={null}>
      <Styles />
      {!v2 &&
        <StylesV1 />
      }
      {v2 &&
        <StylesV2 />
      }
      {v2 && mobile() &&
        <StylesV2Mobile />
      }
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../${routeItem.root_path}/${version}${routeItem.component}`))}
            />
          )
        })}
      </Switch>
    </Suspense>
  )
}

export default Router
