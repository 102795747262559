import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @import url('https://fonts.googleapis.com/css?family=Rubik');    

    body,
    html,
    a {
        font-family: 'Rubik', 'Roboto', sans-serif;
    }

    a:hover {
        color: #18216d;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #2e186a 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Rubik', 'Roboto', serif;
        color: #18216d;
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    p {
        color: #18216d;
        font-size: 21px;        
        line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #2E186A;

        :hover {
            color: #2e186a;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }

    .mainIntroText3 {
        font-size: 28px;
        line-height: 28px;
        letter-spacing: -1px;
    }

    .mainIntroText4 {
        font-size: 22px;
        line-height: 22px;
        letter-spacing: -1px;
    }

    .demoForm {
        color: #000;
    }

    .demoFormSub {
        text-align: center;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 8px;
        margin: 50px auto;
        max-width: 500px;
    }

    .demoFormText1 {
        font-size: 20px;
        padding: 20px 0 0 0;
        font-weight: bold;
        letter-spacing: -1px;
    }

    .demoFormText2 {
        font-size: 16px;
    }

    .btSendDemoForm{
        padding: 30px;
    }

    .demoFormFieldsGroup{
        padding: 20px 60px 20px 50px;
    }

    .createFormFieldsGroup{
        padding: 20px 14px 20px 14px;
    }

    input:focus {
        box-shadow: none;
    }

    .demoFormAgree {
        display: inline-flex;
        font-size: 12px;
        text-align: left;
        letter-spacing: -1px;
        color: #313131;
    }

    .demoFormAgreeCheck {
        width: 40px;
    }

    .demoFormAgreeText {        
        width: 90%;
    }

    .block1Sub {
        width: 310px;
        text-transform: uppercase;
        text-align: center;
        margin: auto;
        padding: 10px 0 100px 0px;
    }

    .block1Text1 {
        line-height: 14px;
    }

    .block1Text2 {
        font-size: 58px;
        font-weight: 600;
        line-height: 58px;
        padding: 10px 0;
    }

    .block1Text3 {
        font-size: 22px;
        font-weight: 500;
        line-height: 22px;
        color: #BDDB64;
    }

    .block2 {
        padding: 0 0 100px 0;
    }

    .block2Sub {
        display: inline-flex;
    }

    .infoWidget {
        padding: 0 2.5em;
    }

    .infoWidgetText {
        font-size: 22px;
        line-height: 22px;
        text-transform: uppercase;
        letter-spacing: -2px;
    }

    .infoWidgetDesc {
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -1px;
        text-align: left;
    }

    .infoWidgetSeparator {
        height: 1px;
        background: #BDDB64;
        width: 30%;
        margin: 10px 0px;
    }

    .block3Sub {
        text-align: center;
    }

    .block3 {
        padding: 0 0 20px 0;
    }

    .block3Sub img {
        max-width: 750px;
    }

    @media (max-width: 959px) {    
        .block3Video {
            margin: 10px 0;
            width: 320px !important;
            height: 180px !important;
        }
    }

    .block3Video {
        margin: 10px 0;
        width: 560px;
        height: 315px;
    }

    .block4 {
        height: 300px;
        background: url("/img/bgdiv.png");
        padding: 40px 0 0 0;
    }

    .block4Sub {
        width: 300px;
        margin: auto;
        text-transform: uppercase;
    }

    .block4rightCorner {
        text-align: right;
    }

    .block4Text1 {
        font-size: 60px;
        line-height: 60px;
        letter-spacing: -6px;
    }

    .block4Text2 {
        padding: 0 0 0 20px;
        line-height: 18px;
    }

    .companiesList {
        height: 300px;
        display: flex;
        animation: scrolling 12s linear infinite;
    }

    .marquee-item img{
        height: 36px;
    }

    .companiesList div {
        padding: 50px 30px;
    }

    @keyframes scrolling {
        0% { transform: translateX(0); }
        100% { transform: translatex(-550px); }
    }

    .block5 {
        padding: 30px 0 100px 0;
        background: #B8D662;        
    }

    .block5Sub {        
        max-width: 750px;
        margin: auto;
    }

    .testimonials {
        color: #000;
        display: inline-flex;
    }

    .testimonials_images {
        text-align: center;
    }
    
    .testimonials_pic {
        padding: 10px;
    }

    .testimonials_text {
        padding: 40px;
    }

    .testimonials_name {
        font-weight: 600;
    }

    .testimonials_desc {
        text-align: justify;
        line-height: 20px;
    }

    .testimonials_person {
        padding: 20px 0;
        text-align: right;
        letter-spacing: -1px;
    }

    .testimonials.tleft .testimonials_person {
        text-align: left;
    }

    .testimonials_quote {
        height: 20px;
        margin: 0 4px 10px 0px;
    }

    .testimonials_pic img {
        border-radius: 3px;
    }

    .contactDesc {
        text-align: center;
        font-size: 18px;
        line-height: 20px;
        text-transform: uppercase;
        letter-spacing: -1px;
        color: #000;
        padding: 40px;
    }

    .contactButton {
        text-align: center;
    }

    a.social {
        filter: invert(99%);
        margin: 0 8px;
    }

    .ant-row.footerCopyright {
        text-align: right;
        display: block;
        letter-spacing: -1px;
        padding: 10px 0;
    }

    .contactButton {
        background: url("/img/request_demo.png") no-repeat;
        background-size: contain;
        height: 90px;
        background-position-x: center;
    }

    .contactButtonLabel {
        line-height: 70px;
        font-size: 22px;
        color: #B8D662;
        letter-spacing: -1px;
        text-transform: uppercase;
    }

    .thankYouFirstSteps {
        height: 100px;
        background-image: linear-gradient(to bottom, transparent 0%, black 100%);
    }

    .thankYouFirstStepsText1 {
        text-align: center;
        font-size: 28px;
        padding: 50px 0px 0px 0px;
    }

    .thankYouVideos{
        background: #000;
    }

    .thankYouMainVideoBlock {
        text-align: center;
    }

    .thankYouMainVideo {
        width: 750px;
        height: 450px;
    }

    .createMainVideo {
        width: 100%;
        height: 100%;
        padding: 60px 0;
    }

    .thankYouDivider {
        height: 1px;
        width: 100%;
        background: #FFF;
    }

    .thankYouVideoBlock {
        text-align: center;
        padding: 50px;
    }

    .thankYouVideosBlock {
        display: flex;
    }

    .thankYouVideoBlockTitle {
        color: #BDDB64;
        font-size: 20px;
        letter-spacing: -1px;
        line-height: 40px;
        font-weight: 500;
        text-align: left;
    }

    .thankYouVideoBlockDesc {
        text-align: left;
        line-height: 16px;
        letter-spacing: -1px;
    }

    .thankYouVideoBlock object {
        width: 100%;
    }

    .thankYouStoreBlock {
        text-align: center;
        text-transform: uppercase;
        letter-spacing: -1px;
        padding: 50px 0 100px 0;
    }

    .thankYouStoreText1 {
        font-size: 36px;        
    }

    .thankYouStoreText2 {
        color: #BDDB64;
        font-size: 20px;        
    }

    .thankYouStoreImages img {
        width: 200px;
        margin: 4px;
    }

    .registerForm {
        height: 900px;
        background: url("/img/bg_register.png") no-repeat;
        background-size: cover;
        padding: 100px 0;
    }

    .registerFormFieldsGroup {
        max-width: 500px;
        margin: auto;
        border-radius: 8px;
        background: rgba(255,255,255,.9);
        padding: 40px;
        color: #000;
        text-align: center;
    }

    .registerLockIcon {
        text-align: center;
        padding: 30px;
    }

    .registerLockInput {
        width: 40px;
        font-weight: bold !important;
        margin: 30px 3px;
    }

    .registerLockValidateButton {
        padding: 20px 0 0 0;
    }

    .verificationCodeTitle {
        font-size: 22px;
    }

    .verificationCodeOption {
        display: inline-flex;
        text-align: left;
        line-height: 18px;
        padding: 30px 0px 14px 0;
        border-bottom: 1px solid #C6C6C6;
        width: 100%;
    }

    .verificationCodeOptionDesc {
        padding: 0px 0px 0px 14px;
        color: #515151;
    }

    .verificationCodeCheckOptions {
        text-align: left;
        padding: 30px 0;
    }

    .verificationFormFields {
        padding: 30px 0;
    }

    .verificationFormPasswdHint {
        padding: 0 0 30px 0px;  
    }

    .registerFormFieldsGroup.registerFormFieldsGroupStep7 {
        max-width: initial;
    }

    .registerTemplateTitle {
        font-size: 15px;
        line-height: 20px;
        padding: 14px 0;
    }

    .registerTemplateIcon img {
        height: 54px;
    }

    .registerTemplateDesc {
        font-size: 13px;
        text-align: left;
        color: #515151;
        line-height: 13px;
    }

    .registerCompleteImage {
        padding: padding: 0 0 20px 0;
    }

    .registerTemplate {
        background: #fff;
        border-radius: 6px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 20px;
        height: 360px;
        position: relative;
    }

    .registerTemplate.active {
        border: 2px solid #005DFF;
        box-shadow: rgb(0, 92, 254) 1px 1px 2px -1px
    }

    .ribbon {
        position: absolute;
        right: -8px;
        top: -8px;
    }

    .logoRegister {
        position: fixed;
        left: 20px;
        top: 20px;
    }

    .connectSocial{
        color: #fff;
        font-size: 13px;
    }

    .connectSocialGoogle {
        background: #de4032;
        padding: 3px 20px;
        border-radius: 14px;
        white-space: nowrap;
        letter-spacing: -1px;
    }

    .connectSocialFacebook {
        background: #1778f2;
        padding: 3px 20px;
        border-radius: 14px;
        margin: 10px;
        white-space: nowrap;
        letter-spacing: -1px;
    }

    .connectSocialLinkedin {
        background: #0a66c2;
        padding: 3px 20px;
        border-radius: 14px;
        margin: 10px;
        white-space: nowrap;
        letter-spacing: -1px;
    }

    .divAccessAccount {
        padding: 20px 0 10px 0;
        font-size: 13px;
        font-weight: bold;
        color: #333333;
    }

    .Toastify__toast{
        width: 400px;
        padding: 0 !important;
        background: transparent !important;
    }

    .Toastify__toast-body{
        padding: 0 !important;
    }

    .Toastify__toast-container--top-right {
        position: absolute !important;
        right: 0px !important;
        top: 0px !important;
        text-align: right;
    }
    
`;


export const StylesV1 = createGlobalStyle`
    header {
        background: #000;
    }

    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: url("/img/bg1.jpg") #030D17 no-repeat 0 30px;
        background-size: cover;
        overflow-x: hidden;
        color: #fff;
    }

    .mainIntroText {
        text-align: center;
        margin: 100px 0 0px 0px;
    }

    .mainIntroText1 {        
        font-size: 32px;
        line-height: 32px;
        letter-spacing: -1px;
    }

    .mainIntroText2 {
        font-size: 64px;
        line-height: 80px;
        letter-spacing: -5px;
    }
`;


export const StylesV2 = createGlobalStyle`

    @import url('https://fonts.googleapis.com/css?family=Rubik');

    header {
        background: #fff;
    }

    header span{
        color: #919191;
    }

    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #f6f6f6;
        overflow-x: hidden;
        color: #3C3B3A;
        font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    }

    .mainIntroText {
        text-align: left;
        margin: 30px 0 0px 0px;
    }

    .mainIntroText1 {        
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -1px;
    }

    .mainIntroText1 span {
        color: #ff742e;
    }

    .mainIntroText2 {
        margin-top: 14px;
        font-size: 18px;
        line-height: 24px;
    }

    .blockCategory {
        max-width: 800px;
    }

    .blockCategorySub {
        text-align: left;
        margin: auto;
    }

    .categoryText1 {
        font-size: 22px;
        line-height: 26px;
        font-weight: bold;
    }

    .categoryText2 {
        font-size: 17px;
        line-height: 20px;
        padding: 9px 0;
        max-width: 712px;
    }

    .categoryItem {
        width: 136px;
        height: 124px;
        background: #fff;
        border-radius: 4px;
        margin: 8px 8px 0 0;
        float: left;
    }

    .categoryItem.active {
        background: #0F82F4;        
    }

    .categoryItem.active .categoryItemDesc{
        color: #ffffff;
    }

    .categoryItemBT { 
        width: 120px;
        height: 112px;       
        padding: 20px 0;
        text-align: center;
    }

    .categoryItemBT.notEnable{
        opacity: .5;
        cursor: initial
    }

    .categoryItemDesc{
        line-height: 12px;
        padding: 10px 0 0 0;
        text-transform: initial;
        color: #373737;
        font-family: 'Rubik', 'Roboto',sans-serif;
        font-weight: bold;
        font-size: 12px;
    }

    .finishLater {
        font-weight: bold;
        text-transform: uppercase;
        color: #777;
    }

    textarea:focus-within {
        box-shadow: #f0f0f000 0px 0px 0px 0px;
    }

    .MuiDialog-paperWidthMd {
        max-width: 750px !important;
    }

    .integrationsList {
        animation: scrollingY 12s linear infinite;
    }

    @keyframes scrollingY {
        0% { transform: translateY(0); }
        100% { transform: translatey(-550px); }
    }

    .Toastify__toast-container--bottom-right {
        width: 400px !important;
    }

    button#rfs-btn {
        border: 0 !important;
    }

    .categoryItemPill{
        float:left;
    }

    .categoryItemPill img{
        height:24px;
        margin-right:14px;
    }

    .categoryItemPill button {
        background: #ffffff;
        margin: 6px;
        font-size: 13px;
        padding: 10px;
        box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
    }

    .categoryItemPill.active button {
        background: #1976d2;
        color: #ffffff;
    }

`;

export const StylesV2Mobile = createGlobalStyle`

    @import url('https://fonts.googleapis.com/css?family=Rubik');


    h1, h2, h3, h4, h5, h6 {
        font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    }

    .mainIntroText1 {        
        font-size: 26px;
        line-height: 32px;
    }

    .mainIntroText2 {
        margin-top: 20px;
        font-size: 16px;
        line-height: 18px;
    }

    .categoryItem {
        width: 30%;
        height: 90px;
        background: #fff;
        border-radius: 4px;
        margin: 4px;
        float: left;
    }

    .categoryItemBT { 
        width: 90px;
        height:74px;
        padding: 6px 0;
        text-align: center;
    }

    .categoryItemBT img { 
        height: 40px;
        max-width: 64px;
    }

    .categoryItemDesc {
        line-height: 10px;
        padding: 8px 0 0 0;
        text-transform: initial;
        color: #373737;
        font-family: 'Rubik', 'Roboto',sans-serif;
        font-weight: bold;
        font-size: 10px;
    }

    .MuiPaper-root.MuiDrawer-paper {
        border-radius: 20px 20px 0 0;
    }

    .mobileNav.mobileNav1 {
        height: 100vh;
        width: 100%;
        background: #ffffff;
    }

    .mobileNav.mobileNav1 header {
        background: transparent;
    }


`;